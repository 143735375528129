
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logo from '../assets/images/logo-color.png';
import LogoWhite from '../assets/images/logo-white.png';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
const MenuBar=()=>{
    const [expanded, setExpanded] = useState(false);
    const [navBg, setNavBg] = useState('bg-body-tertiary navFixed');
    const [logoMenu, setLogoMenu] = useState(LogoWhite)
    const changeNavBg = e => {
        window.scrollY >= 100 ? setNavBg('bg-body-tertiary navFixedWhite') : setNavBg('bg-body-tertiary navFixed');
        window.scrollY >= 100 ? setLogoMenu(Logo) : setLogoMenu(LogoWhite);
    };
    useEffect(() => {
        window.addEventListener('scroll', changeNavBg);
        return () => {
            window.removeEventListener('scroll', changeNavBg);
        };
    }, []);
    return(
        <>
        <Navbar expand="lg" className={navBg} expanded={expanded}>
                <Container>
                    <Link to="/" className="navbar-brand" onClick={() => setExpanded(false)}>
                        <img src={logoMenu} className='logoWhite' />
                    </Link>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setExpanded(expanded ? false : "expanded")}/>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Link to="/" className='nav-link' onClick={() => setExpanded(false)}>Home</Link>
                            <Link to="/about-us" className='nav-link' onClick={() => setExpanded(false)}>About Us</Link>
                            <Link to="/training-programs" className='nav-link' onClick={() => setExpanded(false)}>Training Programs</Link>
                            <Link to="/contact-us" className='nav-link' onClick={() => setExpanded(false)}>Contact Us</Link>
                        </Nav>
                        <div className="d-flex socialLinks">
                            <Nav.Link href="https://www.facebook.com/profile.php?id=100093207592225&mibextid=ZbWKwL" target='_blank'><i className="fa fa-facebook-square"></i></Nav.Link>
                            <Nav.Link href="https://instagram.com/nexus_sports_academy?utm_source=qr&igshid=MzNlNGNkZWQ4Mg%3D%3D" target='_blank'><i className="fa fa-instagram"></i></Nav.Link>
                            <Nav.Link href="#">Portal Login</Nav.Link>

                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            </>
    )
}
export default MenuBar
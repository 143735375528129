import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from "./pages/About";
import Contact from "./pages/Contact";
import Football from "./pages/Football";
import Badminton from "./pages/Badminton";
import Cricket from "./pages/Cricket";
import Basketball from "./pages/Basketball";
import Sports from "./pages/Sports";
import MenuBar from "./components/MenuBar";
import './App.css';

function App() {
  return (
    <div className="App">
      {/* <BrowserRouter> */}
      <MenuBar />
        <Routes >
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/football" element={<Football />} />
          <Route path="/badminton" element={<Badminton />} />
          <Route path="/cricket" element={<Cricket />} />
          <Route path="/basketball" element={<Basketball />} />
          <Route path="/training-programs" element={<Sports />} />
        </Routes>
      {/* </BrowserRouter> */}
    </div>
  );
}

export default App;

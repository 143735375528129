import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect } from "react";
import Footer from '../components/Footer';
import ExampleCarouselImage from '../assets/images/football-banner.jpg';
import ExampleCarouselImageMob from '../assets/images/football-banner-mob.jpg';
import About01 from '../assets/images/about-us-inn.jpg'
import About02 from '../assets/images/ashu-dada.jpg'
const About = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            <Carousel fade className='aboutCarousel'>
                <Carousel.Item>
                    <img src={ExampleCarouselImageMob} style={{ width: '100%' }} className='desktopHide' />
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} className='mobHide' />
                    <Carousel.Caption className='aboutCarCaption aboutCarCaptionMob'>
                        <h4 className='sliderSmTitle'>ABOUT US</h4>
                        <h3 className='sliderText'>PUSHING LIMITS<span><br />ACHIEVING GREATNESS</span></h3>
                    </Carousel.Caption>
                    {/* <div className='shadow'></div> */}
                </Carousel.Item>
            </Carousel>
            <div className='HomePatchTwo marginBtm4'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='	fa fa-diamond pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHY CHOOSE US?</h5>
                                    <p>Development of the whole child.</p>
                                </div>
                            </div>
                            <p>The goal of Renapurkar's Nexus Sports Academy is to develop the next generation of leaders via sports, academics, and professional development. Through athletics, our programmes support the promotion of peace, prosperity, and equality, which are key tenets of the communities we serve.</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='fa fa-trophy pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHAT WE DO?</h5>
                                    <p>Staying active, keeping fit.</p>
                                </div>
                            </div>
                            <p>It's a complete-circle institution created to modernise the approach to human performance for men, women, youth, and students by establishing a multi-platform environment that allows people to realise their full potential, educate, and enable present possibilities.</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='	fa fa-diamond pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHO CAN JOIN?</h5>
                                    <p>Individuals that are engaged in sports.</p>
                                </div>
                            </div>
                            <p>Individuals that are engaged in sports and aspire to progress in their athletic careers. The Academy provides students with a full educational program that emphasises athletic growth and training built on the highest level of technical proficiency.We are aware that the next generation will determine how Indian sports develop. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 marginTop2 mobMarginBtm'>
                        <div className='aboutCarCaption '>
                            <h4 className='sliderSmTitle'>ABOUT US</h4>
                            <h3 className='sliderText fontColorBlack'>ONE TEAM<br /><span>ONE SPIRIT</span></h3>
                            <p>The Nexus Sports Academy in Nanded, operated by Ashish Renapurkar, was specifically designed as a multi-sports training facility that encourages athleticism, balance, speed, and agility. It is already acknowledged as the industry standard for training and coaching elite athletes who can compete at the highest levels. Along with providing a competitive environment and the most modern and scientific sports training.</p>
                            <p>The goal is to foster links with the local community and schools while promoting sport at all ODI levels, from youth development to professional competition. Children can concentrate on a variety of games that help them develop their skills because there is less possibility of emotional burnout.</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 somi'>
                        <img src={About01} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 somi'>
                        <img src={About02} style={{ width: '100%' }} />
                    </div>
                    <div className='col-12 col-md-6 mobMarginBtm marginTop2'>
                        <div className='aboutCarCaption '>
                            <h4 className='sliderSmTitle'>ABOUT</h4>
                            <h3 className='sliderText fontColorBlack'>ASHISH<br /><span>RENAPURKAR</span></h3>
                            <p>Many athletes are being encouraged by the Indian government's "Khelo India Initiative" to pursue careers in their chosen sports. As part of this idea, Ashish Renapurkar sought to establish Nexus Sports Academy, where all athletes or students from rural Nanded or metropolitan areas who are capable and motivated should receive the right education. He thinks that many athletes could be successful if they worked hard, had access to opportunities, and had high-quality facilities. There will surely be a significant change in society as a result of the Multiple Sports Academy concept.</p>
                            <p>In his own words, "I credit my success to the supportive counsel and fast assistance that the government and coaches gave. With similar help, I hope to inspire youngsters with larger success stories and pique their interest in sports."</p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default About

import Carousel from 'react-bootstrap/Carousel';
 import ExampleCarouselImage from '../assets/images/banner-3.jpg';
 import ExampleCarouselImageMob from '../assets/images/badminton-banner-mob.jpg';
 import React, { useEffect } from "react";
// import ExampleCarouselImage from '../assets/images/badminton-banner.jpg';
// import ExampleCarouselImage2 from '../assets/images/slider2.jpg';
import BasketballImage from '../assets/images/basketball.png';
// import ImageTwo from '../assets/images/image-2.jpg';
import VideoOne from '../assets/images/video-01.mp4';
import VideoImageOne from '../assets/images/program_01.jpg'
import CricketImg from '../assets/images/cricket-img.jpg'
import BadmintonImg from '../assets/images/badminton-img.jpg'
import FootballImg from '../assets/images/football-img.jpg'
import VideoImageTwo from '../assets/images/program_02.jpg';
import Home02 from '../assets/images/home_01.png';
// import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import { Link } from "react-router-dom";
const Home = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <div>
            {/* <MenuBar /> */}
            <Carousel fade>
                <Carousel.Item>
                    <img src={ExampleCarouselImageMob} style={{ width: '100%' }} className='desktopHide'/>
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} className='mobHide'/>
                    <Carousel.Caption>
                        <h3 className='sliderText'><span>RENAPURKAR'S</span> NEXUS<br />SPORTS ACADEMY</h3>
                    </Carousel.Caption>
                    <div className='shadow'></div>
                </Carousel.Item>
                {/* <Carousel.Item>
                    <img src={ExampleCarouselImage2} style={{ width: '100%' }} />
                    <Carousel.Caption>
                        <h3 className='sliderText'><span>WIN FOR</span> BEARS<br />IN QUATER FINALS</h3>
                    </Carousel.Caption>
                    <div className='shadow'></div>
                </Carousel.Item>
                <Carousel.Item>
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} />
                    <Carousel.Caption>
                        <h3 className='sliderText'><span>WIN FOR</span> BEARS<br />IN QUATER FINALS</h3>
                    </Carousel.Caption>
                    <div className='shadow'></div>
                </Carousel.Item> */}
            </Carousel>
            <div className='container sliderBox'>
                <div className='row'>
                    <div className='col-12 col-md-6 mobMarginBtm'>
                        <div className='boxStyle'>
                            <div className='row'>
                                <div className='col-12 col-md-3 innerImgBox mobHide'>
                                    <img src={BasketballImage} className='imgRes' />
                                </div>
                                <div className='col-12 col-md-9 boxContent'>
                                    <h2><span>THE BEST</span><br />
                                        Cricket/Football Court In Town</h2>
                                    <p>Best Cricket/Football courts in Nanded. Enjoy a fun and active time with our quality facilities.</p>
                                    <button className='buttonCustomRegular'>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className='boxStyle'>
                            <div className='row'>
                                <div className='col-12 col-md-3 innerImgBox mobHide'>
                                    <img src={BasketballImage} className='imgRes' />
                                </div>
                                <div className='col-12 col-md-9 boxContent'>
                                    <h2><span>THE BEST</span><br />
                                        Badminton Court In Town</h2>
                                    <p>Best badminton courts in Nanded. Enjoy a fun and active time with our quality facilities.</p>
                                    <button className='buttonCustomRegular'>Book Now</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='HomePatchTwo'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='	fa fa-diamond pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHY CHOOSE US?</h5>
                                    <p>Development of the whole child.</p>
                                </div>
                            </div>
                            <p>The goal of Renapurkar's Nexus Sports Academy is to develop the next generation of leaders via sports, academics, and professional development. Through athletics, our programmes support the promotion of peace, prosperity, and equality, which are key tenets of the communities we serve.</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='fa fa-trophy pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHAT WE DO?</h5>
                                    <p>Staying active, keeping fit.</p>
                                </div>
                            </div>
                            <p>It's a complete-circle institution created to modernise the approach to human performance for men, women, youth, and students by establishing a multi-platform environment that allows people to realise their full potential, educate, and enable present possibilities.</p>
                        </div>
                        <div className='col-12 col-md-4'>
                            <div className='row'>
                                <div className='col-2'>
                                    <i className='	fa fa-diamond pageIco' />
                                </div>
                                <div className='col-10'>
                                    <h5 className='footerTitleSmall fontColorBlack contentBodyTitle'>WHO CAN JOIN?</h5>
                                    <p>Individuals that are engaged in sports.</p>
                                </div>
                            </div>
                            <p>Individuals that are engaged in sports and aspire to progress in their athletic careers. The Academy provides students with a full educational program that emphasises athletic growth and training built on the highest level of technical proficiency.We are aware that the next generation will determine how Indian sports develop. </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className='videoContainer'>
                <video autoPlay muted loop>
                    <source src={VideoOne} type="video/mp4" />
                </video>
                <div className='videoTitle'>
                    <h2><span>PUSH BEYOND </span> YOUR LIMITS</h2>
                </div>
            </div>

            <div className='videoContentContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-3'>
                            <div className='col boxPlay'>
                                <img src={BadmintonImg} style={{ width: '100%' }} />
                                <div className='contentBody'>
                                    <h4>PROGRAM</h4>
                                    <h2>BADMINTON</h2>
                                    <p>RNSA's Badminton Training Programme uses a thorough and organized process to develop prospective players into accomplished athletes.</p>
                                    <Link to="/training-programs" className='buttonCustomRegular'>Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-3'>
                            <div className='col boxPlay'>
                                <img src={CricketImg} style={{ width: '100%' }} />
                                <div className='contentBody'>
                                    <h4>PROGRAM</h4>
                                    <h2>CRICKET</h2>
                                    <p>The goal of RNSA's cricket training program is to develop aspiring cricketers into well-rounded players through a disciplined curriculum. </p>
                                    <Link to="/training-programs" className='buttonCustomRegular'>Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-3'>
                            <div className='col boxPlay'>
                                <img src={FootballImg} style={{ width: '100%' }} />
                                <div className='contentBody'>
                                    <h4>PROGRAM</h4>
                                    <h2>FOOTBALL</h2>
                                    <p>RNSA's football training program seeks to develop prospective football players into world-class athletes which is run by seasoned coaches.</p>
                                    <Link to="/training-programs" className='buttonCustomRegular'>Know More</Link>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-3'>
                            <div className='col boxPlay'>
                                <img src={VideoImageTwo} style={{ width: '100%' }} />
                                <div className='contentBody'>
                                    <h4>PROGRAM</h4>
                                    <h2>BASKETBALL</h2>
                                    <p>Program is led by qualified instructors, focuses on improving essential abilities such as shooting, dribbling, passing, and defence.</p>
                                    <Link to="/training-programs" className='buttonCustomRegular'>Know More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='AboutContainerPatch'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-7 contentBodyAbout'>
                            <h4>ABOUT US</h4>
                            <h2>WE OFFER THE HIGHEST LEVEL COACHING AND SKILLS DEVELOPMENT</h2>
                            <p>The Nexus Sports Academy in Nanded, operated by Ashish Renapurkar, was specifically designed as a multi-sports training facility that encourages athleticism, balance, speed, and agility. It is already acknowledged as the industry standard for training and coaching elite athletes who can compete at the highest levels. Along with providing a competitive environment and the most modern and scientific sports training. </p>
                            <p>The goal is to foster links with the local community and schools while promoting sport at all ODI levels, from youth development to professional competition. Children can concentrate on a variety of games that help them develop their skills because there is less possibility of emotional burnout.</p>
                        </div>
                        <div className='col-12 col-md-5'>
                            <div className='row'>
                                <div className='col-6 skills'>
                                    <i className='fa fa-star'></i>
                                    <h2>SKILLS DEVELOPMENT</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-recycle"></i>
                                    <h2>DEVELOPMENT PROGRAMS</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-rss"></i>
                                    <h2>ADVANCED TRAINING</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-road"></i>
                                    <h2>ELITE YOUTH COACHING</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='row positionRelative'>
                    <div className='col-6'>
                        <div className='pngTitle'>
                            <h2><span>DISCOVER</span> OUR SERVICES</h2>
                            <p className='mobHide'>Excellence in any field of knowledge is built on certified trainers. The RNSA ensures that its members receive the greatest degree of counseling and education through their rigorous training, specialized knowledge, and dedication to lifelong learning. RNSA's certified trainers bring a lot of expertise and credibility to their practice, whether it be in sports, fitness, or different professional areas. They make excellent mentors because of their commitment to equipping others with the necessary abilities and knowledge, which promotes development and success in those they train. You can feel secure knowing that you are in the hands of true professionals at Renapurkar's NeXus Sports Academy, ready to assist you reach your best potential.</p>
                            <Link to="/training-programs" className='buttonCustomRegular'>Know More</Link>
                        </div>
                    </div>
                    <div className='col-6'><img src={Home02} style={{ width: '100%' }} /></div>
                    <div className='col-12 floatingDiv mobHide'>
                        <div className='col'>
                            <div className='boxStyle'>
                                <div className='row'>
                                    <div className='col-1 innerImgBox'>
                                    </div>
                                    <div className='col-9 boxContent'>
                                        
                                        <p>"When I step onto the court, I don't have to think about anything. If I have a problem off the court, I find that after I play, my mind is clearer and I can come up with a better solution. It's like therapy. It relaxes me and allows me to solve problems."</p>
                                        <h2>
                                        Michael Jordan<br /><span>(Basketball Legend)</span></h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer></Footer>
        </div>
    )
}
export default Home
import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import ExampleCarouselImage from '../assets/images/badminton-banner.jpg';

function Badminton() {
    return (
        <>
            <MenuBar />
            <Carousel fade className='aboutCarousel'>
                <Carousel.Item>
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} />
                    <Carousel.Caption className='aboutCarCaption'>
                        <h4 className='sliderSmTitle'>ABOUT US</h4>
                        <h3 className='sliderText'>ONE TEAM<br /><span>ONE SPIRIT</span></h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <Footer />
        </>
    )
}

export default Badminton
import React, { useEffect } from "react";
import Carousel from 'react-bootstrap/Carousel';
// import MenuBar from '../components/MenuBar';
import Footer from '../components/Footer';
import ExampleCarouselImage from '../assets/images/basketball-banner.jpg';
import ExampleCarouselImageMob from '../assets/images/basketball-banner-mob.jpg';

import BadmintonImage from '../assets/images/badminton-02.jpg'
import CricketImage from '../assets/images/cricket-02.jpg'
import BasketballImage from '../assets/images/basketball-02.jpg'
import FootballImage from '../assets/images/football-02.jpg'
import About02 from '../assets/images/inner_about_02.jpg'
import { Link } from "react-router-dom";

function Sports() {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <>
            {/* <MenuBar /> */}
            <Carousel fade className='aboutCarousel'>
                <Carousel.Item>
                    <img src={ExampleCarouselImageMob} style={{ width: '100%' }} className='desktopHide' />
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} className='mobHide' />
                    <Carousel.Caption className='aboutCarCaption  aboutCarCaptionMob'>
                        <h4 className='sliderSmTitle'>TRAINING PROGRAMS</h4>
                        <h3 className='sliderText'>PASSION. DETERMINATION.<span><br />TEAMWORK. SUCCESS</span></h3>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
            <div className='AboutContainerPatch'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-7 contentBodyAbout'>
                            <h4>ABOUT US</h4>
                            <h2>WE OFFER THE HIGHEST LEVEL COACHING AND SKILLS DEVELOPMENT</h2>
                            <p>The Nexus Sports Academy in Nanded, operated by Ashish Renapurkar, was specifically designed as a multi-sports training facility that encourages athleticism, balance, speed, and agility. It is already acknowledged as the industry standard for training and coaching elite athletes who can compete at the highest levels. Along with providing a competitive environment and the most modern and scientific sports training. </p>
                            <p>The goal is to foster links with the local community and schools while promoting sport at all ODI levels, from youth development to professional competition. Children can concentrate on a variety of games that help them develop their skills because there is less possibility of emotional burnout.</p>
                        </div>
                        <div className='col-12 col-md-5'>
                            <div className='row'>
                                <div className='col-6 skills'>
                                    <i className='fa fa-star'></i>
                                    <h2>SKILLS DEVELOPMENT</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-recycle"></i>
                                    <h2>DEVELOPMENT PROGRAMS</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-rss"></i>
                                    <h2>ADVANCED TRAINING</h2>
                                </div>
                                <div className='col-6 skills'>
                                    <i className="fa fa-road"></i>
                                    <h2>ELITE YOUTH COACHING</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 marginTop2'>
                        <div className='aboutCarCaption '>
                        <h4 className='sliderSmTitle'>BADMINTON</h4>
                            <h3 className='sliderText fontColorBlack'>BEST BADMINTON <br /><span>PROGRAM IN TOWN</span></h3>
                            <p>RNSA's Badminton Training Programme uses a thorough and organized process to develop prospective players into accomplished athletes. The program seeks to improve all parts of a player's game by emphasizing skill, agility, and mental toughness. To improve players' abilities and tactical awareness, qualified coaches conduct challenging drills, conditioning workouts, and match simulations. Participants develop discipline and a competitive edge through routine practice and tailored feedback. The Badminton Training Programme welcomes all levels of players, from novices to seasoned pros, cultivating a passion for the game and creating champions both on and off the court.</p>
                            <Link to="/contact-us" className='buttonCustomRegular'>Contact Us</Link>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 somi'>
                        <img src={BadmintonImage} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 somi'>
                        <img src={CricketImage} style={{ width: '100%' }} />
                    </div>
                    <div className='col-12 col-md-6 marginTop2'>
                        <div className='aboutCarCaption '>
                            <h4 className='sliderSmTitle'>CRICKET</h4>
                            <h3 className='sliderText fontColorBlack'>BEST CRICKET <br /><span>PROGRAM IN TOWN</span></h3>
                            <p>The goal of RNSA's cricket training program is to develop aspiring cricketers into well-rounded players through a thorough and disciplined curriculum. The program, run by seasoned instructors and ex-pros, focuses on improving players' batting, bowling, fielding, and all-around cricketing abilities. Players' technique and tactical awareness are enhanced by rigorous practice sessions and targeted drills. To ensure top performance in high-pressure situations, physical fitness, and mental conditioning are also stressed. Aspiring cricketers get individualized attention and helpful criticism, which promotes their development and instills a strong love for the sport. Young athletes can use the Cricket Training Programme as a launching pad to develop into future sports stars.</p>
                            <Link to="/contact-us" className='buttonCustomRegular'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 marginTop2'>
                        <div className='aboutCarCaption '>
                            <h4 className='sliderSmTitle'>FOOTBALL</h4>
                            <h3 className='sliderText fontColorBlack'>BEST FOOTBALL <br /><span>PROGRAM IN TOWN</span></h3>
                            <p>RNSA's football training program seeks to develop prospective football players into world-class athletes. It is a thorough and dynamic approach. The program, which is run by seasoned coaches with years of expertise, emphasizes the growth of technical abilities, tactical awareness, and physical fitness. Players' passing, dribbling, shooting, and defensive skills are improved through rigorous drills and match simulations, while collaboration and communication are stressed to form a cohesive group. The curriculum instils a winning mentality and teaches participants how to concentrate under pressure and perform well in hostile competition. The Football Training Programme equips young players to meet the demands of the beautiful game and realize their full potential on the field with a strong emphasis on holistic development.</p>
                            <Link to="/contact-us" className='buttonCustomRegular'>Contact Us</Link>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 somi'>
                        <img src={FootballImage} style={{ width: '100%' }} />
                    </div>
                </div>
            </div>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-12 col-md-6 somi'>
                        <img src={BasketballImage} style={{ width: '100%' }} />
                    </div>
                    <div className='col-12 col-md-6 marginTop2 mobMarginBtm'>
                        <div className='aboutCarCaption '>
                            <h4 className='sliderSmTitle'>BASKETBALL</h4>
                            <h3 className='sliderText fontColorBlack'>BEST BASKETBALL <br /><span>PROGRAM IN TOWN</span></h3>
                            <p>RNSA's basketball program, which is led by qualified instructors, focuses on improving essential abilities such as shooting, dribbling, passing, and defence. Drills and scrimmages help players improve their technique and gain a comprehensive understanding of the game's strategies. Physical conditioning and agility activities are used to improve the athleticism and endurance of the players. The basketball program also emphasises cooperation, leadership, and mental toughness, giving athletes the tools, they need to thrive both individually and as part of a cohesive unit. The Basketball Training Programme builds the groundwork for future stars of the hardwood by providing intense training that fosters a lifelong love of the sport.</p>
                            <Link to="/contact-us" className='buttonCustomRegular'>Contact Us</Link>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default Sports
import Carousel from 'react-bootstrap/Carousel';
import React, { useEffect } from "react";
import Footer from '../components/Footer';
import ExampleCarouselImage from '../assets/images/contact-banner.jpg';
import ExampleCarouselImageMob from '../assets/images/contact-banner-mob.jpg';
// import GymImage from '../assets/images/gym.jpg';
const Contact = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
    return (
        <>
            <Carousel fade className='aboutCarousel'>
                <Carousel.Item>
                    <img src={ExampleCarouselImageMob} style={{ width: '100%' }} className='desktopHide'/>
                    <img src={ExampleCarouselImage} style={{ width: '100%' }} className='mobHide'/>
                    <Carousel.Caption className='aboutCarCaption  aboutCarCaptionMob'>
                        <h4 className='sliderSmTitle'>CONTACT US</h4>
                        <h3 className='sliderText'>GET IN <span>TOUCH</span></h3>
                    </Carousel.Caption>
                    {/* <div className='shadow'></div> */}
                </Carousel.Item>
            </Carousel>
            <div className='formContainer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-md-5'>
                            <h4>The feedback of our members and supporters is important to us and we are dedicated to maintaining the highest possible levels of customer service.</h4>
                            <p>To help us to best communicate with you, please provide us with your full name, phone number, email address and postal address whenever you contact us by email or post.</p>
                        </div>
                        <div className='col-2 mobHide'></div>
                        <div className='col-12 col-md-5'>
                            <h4>Connect With Us</h4>
                            <div>
                                <div className='row'>
                                    <div className='col-6'>
                                        <input type="text" placeholder='First Name*' className='inputType' />
                                    </div>
                                    <div className='col-6'>
                                        <input type="text" placeholder='Last Name*' className='inputType' />
                                    </div>
                                    <div className='col-6'>
                                        <input type="text" placeholder='Email Id*' className='inputType' />
                                    </div>
                                    <div className='col-6'>
                                        <input type="text" placeholder='Contact Number*' className='inputType' />
                                    </div>
                                    <div className='col-12'>
                                        <textarea placeholder='Message*' className='inputType' />
                                    </div>
                                    <div className='col-12'>
                                        <button className='buttonCustomRegular'>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ImgBgContact'>
                {/* <img src={GymImage} className='imgRes float' /> */}
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-12 col-md-6 somi google-maps'>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d21685.08626504323!2d77.30654345723491!3d19.16677468957674!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bd1d7363b51b5c5%3A0x441cc2e95792f5b5!2sRenapurkar&#39;s%20Nexus%20Sports%20Academy!5e0!3m2!1sen!2sin!4v1690536608672!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                        </div>
                        <div className='col-12 col-md-6 bgPinkShade'>
                            <div className='borderRight'>
                                <h4 className='sliderSmTitle'>CONTACT US</h4>
                                <h3 className='sliderText'>GET IN <span>TOUCH</span></h3>
                            </div>
                            <div className='addressClass'>
                                <p className=''>Renapurkar's Nexus, Sports Academy, Guru Gobind Singh Ji Rd, opp. Bhargav class, near govt swimming pool, Industrial Estate, Vishnu Nagar, Nanded, Nanded-Waghala, Maharashtra 431605</p>
                                <p>+91 89569 87888</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default Contact
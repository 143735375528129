import SponserImageOne from '../assets/images/sponsor1.png'
import SponserImageTwo from '../assets/images/sponsor2.png'
import SponserImageThree from '../assets/images/sponsor3.png'
import SponserImageFour from '../assets/images/sponsor4.png'
import SponserImageFive from '../assets/images/sponsor5.png'
import SponserImageSix from '../assets/images/sponsor6.png'
import Logo from '../assets/images/logo-white.png'
import { Link } from "react-router-dom";
const Footer = () => {
    return (
        <div className="footer">
            <div className="footerTop">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h4 className='footerTitle'>OUR SPONSORS</h4>
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageOne} style={{ width: '100%' }} />
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageTwo} style={{ width: '100%' }} />
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageThree} style={{ width: '100%' }} />
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageFour} style={{ width: '100%' }} />
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageFive} style={{ width: '100%' }} />
                        </div>
                        <div className="col-4 col-md-2">
                            <img src={SponserImageSix} style={{ width: '100%' }} />
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerBottom'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-6 col-md-3'>
                            <ul className='footerLinks'>
                                <li><img src={Logo} className='logoWhite' /></li><br/>
                                <li>Privacy Policy</li>
                                <li>Terms and Conditions</li>
                            </ul>
                        </div>
                        <div className='col-6 col-md-3 mobHide'>
                            <h5 className='footerTitleSmall'>ABOUT US</h5>
                            <ul className='footerLinks'>
                                <li>We wish to provide equal opportunity to everyone, thus young men, ladies, or any individual interested in the sports we offer are all welcome. Our Sports Academy is a talent-development programme created to assist you in becoming the finest sportsperson possible.</li>
                            </ul>
                        </div>
                        <div className='col-6 col-md-3'>
                            <h5 className='footerTitleSmall'>QUICK LINKS</h5>
                            <ul className='footerLinks'>
                                <li><Link to="/" className='linkClass'>Home</Link></li>
                                <li><Link to="/about-us" className='linkClass'>About Us</Link></li>
                                <li><Link to="/training-programs" className='linkClass'>Training Programs</Link></li>
                                <li><Link to="/contact-us" className='linkClass'>Contact Us</Link></li>
                                <li>Portal Login</li>
                            </ul>
                        </div>
                        <div className='col-6 col-md-3 mobHide'>
                            <h5 className='footerTitleSmall'>CONTACT US</h5>
                            <ul className='footerLinks'>
                                <li>Renapurkar's Nexus, Sports Academy, Guru Gobind Singh Ji Rd, opp. Bhargav class, near govt swimming pool, Industrial Estate, Vishnu Nagar, Nanded, Nanded-Waghala, Maharashtra 431605</li>
                                <li>+91 89569 87888</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='footerTextBottom'>
                <div className='container'>
                    <div className='row'>
                        <p className='copyright'>@2023 All copyright reserved. Developed by: <a href="https://soumitranath.com/" className='linkClass' target='_blank'>Soumitra Nath</a></p>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Footer